import Cookies from 'js-cookie';
import {} from 'universal-cookie';
import { getApiClient } from 'api-client/axios';
import { isSuccessfulRequest } from '@/utils/is-successful-request';
import { ApiError, isAxiosError } from '@/utils/errors';
import { CreateUserV1, PaginatedListV1 } from '@/types/api-models/v1';
import { isNil } from 'lodash';
import { UseQueryOptions } from 'react-query';
import { AxiosInstance } from 'axios';
import { logger } from './logging';
import { addDays, addYears } from 'date-fns';
export const API_KEY_NAME = 'api-key';

export function getExpiresTime(remember: boolean = false): Date {
  const now = new Date();
  return remember ? addYears(now, 1) : addDays(now, 1);
}

export function saveApiKey(apiKey: string, remember: boolean = false) {
  Cookies.set(API_KEY_NAME, apiKey, { expires: getExpiresTime(remember) });
}

export function removeApiKey() {
  Cookies.remove(API_KEY_NAME);
}

export function getUserApiKey() {
  return Cookies.get(API_KEY_NAME);
}

function isAuthenticated(): boolean {
  const apiKey = getUserApiKey() ?? '';

  // TODO: Need to get api_key validation
  return apiKey !== '';
}

type SignInProps = {
  email: string;
  password: string;
  staySignedIn?: boolean;
};

export class InvalidCredentialsError extends Error {}

export async function fetchApiKey(
  axios: AxiosInstance,
  email: string,
  password: string,
) {
  try {
    const res = await axios.get<PaginatedListV1<any>>(`api/v1/api_key`, {
      auth: {
        username: email,
        password: password,
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    const apiKey: string | undefined = res.data.objects[0]?.key;
    if (!apiKey) {
      logger.warn('user %s has no api key', email);
      throw new InvalidCredentialsError();
    }
    return apiKey;
  } catch (e) {
    if (
      isAxiosError(e) &&
      (e.response?.status === 401 || e.response?.status === 403)
    ) {
      const invalidCreds = new InvalidCredentialsError();
      invalidCreds.cause = e;
      throw invalidCreds;
    }
    throw e;
  }
}

const signin = async ({
  email,
  password,
  staySignedIn = false,
}: SignInProps) => {
  const httpClient = getApiClient();
  // httpClient.interceptors.response.use(
  //   function (response) {
  //     return response;
  //   },
  //   async (err) => {
  //     if (err.response.status === 401) {
  //       return Promise.reject('Unauthorized');
  //     }
  //   },
  // );

  const resp = await httpClient.get<PaginatedListV1<any>>(`api/v1/api_key`, {
    auth: {
      username: email,
      password: password,
    },
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

  if (resp?.data?.objects.length) {
    const apiKey = resp.data.objects[0].key;
    saveApiKey(`ApiKey ${email}:${apiKey}`, staySignedIn);

    return true;
  }

  return false;
};

const signout = async () => {
  return new Promise((resolve) => {
    removeApiKey();
    resolve(true);
  });
};

export type AuthenticatedApiProps<PostData = any> = {
  endpoint: string;
  postData?: PostData;
  /**
   * @deprecated
   * Use Method instead
   */
  type?: 'GET' | 'POST' | 'PATCH';
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'OPTION' | 'HEAD';
  enabled?: boolean;
  queryOptions?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>;
};

const apiRequest = async <D = any>({
  endpoint,
  postData = undefined,
  method,
  ...rest
}: AuthenticatedApiProps<D>) => {
  const httpClient = getApiClient();
  const apiKey = getUserApiKey() ?? '';
  const defaultMethod = isNil(postData) ? 'GET' : 'POST';
  method = method ?? rest.type ?? defaultMethod;
  const url = `api/v1/${endpoint}`;
  const resp = await httpClient.request({
    method,
    url,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: apiKey,
    },
    data: postData,
  });

  if (!resp.data && isSuccessfulRequest(resp.status)) {
    return {
      success: true,
    };
  }

  if (Array.isArray(resp.data?.objects)) {
    return resp.data.objects;
  }

  return resp.data;
};

export type PasswordChangeProps = {
  householdId: number;
  email: string;
  userApiKey: string;
  newPassword: string;
};

const changePassword = async ({
  householdId,
  email,
  userApiKey,
  newPassword,
}: PasswordChangeProps) => {
  const httpClient = getApiClient();
  const apiKey = `ApiKey ${email}:${userApiKey}`;
  const resp = await httpClient.patch(
    `api/v1/household_set_password/${householdId}`,
    { new_password: newPassword },
    {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: apiKey,
      },
    },
  );

  if (!resp.data && isSuccessfulRequest(resp.status)) {
    return {
      success: true,
    };
  }

  if (Array.isArray(resp.data?.objects)) {
    return resp.data.objects;
  }

  return resp.data;
};

async function apiAdminRequest<D = any>({
  endpoint,
  postData = undefined,
  method,
  ...rest
}: AuthenticatedApiProps<D>) {
  const httpAdminClient = getApiClient();
  const url = `api/admin/${endpoint}`;
  const defaultMethod = isNil(postData) ? 'GET' : 'POST';
  method = method ?? rest.type ?? defaultMethod;
  const resp = await httpAdminClient
    .request({
      url,
      method,
      data: postData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .catch((err) => {
      return err.isAxiosError ? new ApiError(err) : err;
    });

  // Login with user account
  if (
    resp.status === 201 &&
    endpoint === 'create_user' &&
    typeof postData === 'object'
  ) {
    const createUserData: CreateUserV1 = postData as any;
    const signedIn = await signin({
      email: createUserData.email,
      password: createUserData.password,
    });

    return {
      success: signedIn,
    };
  }

  if (!resp.data && isSuccessfulRequest(resp.status)) {
    return {
      success: true,
    };
  }

  if (resp.data?.objects?.length > 0) {
    return resp.data.objects;
  }
}

const AuthService = {
  signin,
  signout,
  changePassword,
  apiRequest,
  apiAdminRequest,
  getUserApiKey,
  isAuthenticated,
};

export default AuthService;
