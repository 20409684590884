import Image from 'next/image';
import instacartCarrotLogo from '/public/images/instacart/Instacart_Carrot.svg';
import instacartCashewLogo from '/public/images/instacart/Instacart_Logo_Cashew.svg';
import instacartKaleLogo from '/public/images/instacart/Instacart_Logo_Kale.svg';
import instacartAllWhiteLogo from '/public/images/instacart/Instacart_Logo_AllWhite.svg';
import { logger } from '@/services/logging';
import styled from '@emotion/styled';
export function InstacartCarrotLogo() {
  return (
    <Image
      src={instacartCarrotLogo}
      alt="Instacart"
      height={20}
      width={150}
    ></Image>
  );
}

export interface Props {
  color?: 'kale' | 'cashew' | 'all-white';
}

const logoColorMap = {
  kale: instacartKaleLogo,
  cashew: instacartCashewLogo,
  'all-white': instacartAllWhiteLogo,
};

export function FullInstacartLogo({ color = 'cashew' }: Props) {
  let logoSrc = logoColorMap[color];
  if (!logoSrc) {
    logger.warn(`invalid logo color "${color}"`);
    logoSrc = instacartCarrotLogo;
  }
  return <Image src={logoSrc} alt="Instacart" height={20} width={150}></Image>;
}
