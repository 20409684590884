/**
 * Some of our zustand stores have react prop or context dependencies. For example,
 * stores using indexeddb persistence need access to the react localforage context
 * They cannot be declared as module-scope variables, and instead have to be
 * converted into factory functions, which are provided using react contexts.
 */

import { createContext, PropsWithChildren, useEffect, useRef } from 'react';
import { createStoreHook as createPromoNotificationStore } from './promo-notification-store';
import { useRequiredContext } from '@/utils/providers';
import { useLocalforage } from '@/context/localforage';
import { logger } from '@/services/logging';

export type PromoNotificationStore = ReturnType<
  typeof createPromoNotificationStore
>;

export const PromoNotificationStoreContext =
  createContext<null | PromoNotificationStore>(null);

export function PromoNotificationStoreProvider({
  children,
  db,
}: PropsWithChildren<{ db?: LocalForage }>) {
  const { db: defaultDb } = useLocalforage();
  db = db ?? defaultDb;
  const useStoreRef = useRef(createPromoNotificationStore(db));

  return (
    <PromoNotificationStoreContext.Provider value={useStoreRef.current}>
      {children}
    </PromoNotificationStoreContext.Provider>
  );
}
export function usePromoNotificationStore() {
  const ctx = useRequiredContext(
    PromoNotificationStoreContext,
    'PromoNotificationStoreProvider',
  );
  return ctx;
}
/**
 * Provider injecting any stores that require react context injection for the application.
 * Convenience component that eliminates the need to declare each store provider
 * @param param0
 */
export function DefaultStoreProvider({ children }: PropsWithChildren) {
  return (
    <PromoNotificationStoreProvider>{children}</PromoNotificationStoreProvider>
  );
}
