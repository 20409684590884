var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"jLy0B5KxfQjBwQMWdwOxY"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { logger } from '@/services/logging/';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SAMPLE_RATE = process.env.NEXT_SENTRY_CLIENT_SAMPLE_RATE
  ? Number.parseFloat(process.env.NEXT_SENTRY_CLIENT_SAMPLE_RATE)
  : 1.0;
logger.info('sentry is enabled');
Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://d3a7d40b4fb14ecf9502796c73caf972@o339787.ingest.sentry.io/4504369129259008',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SAMPLE_RATE,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  debug: !!process.env.NEXT_SENTRY_DEBUG,
});
